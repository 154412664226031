import { callApi } from "./api-utils"
import Cookies from 'js-cookie'

const getCorporations = data => {
  return callApi({
    method: "GET",
    path: "/corporations",
    data: data,
  })
}

const getCorporationsById = (id, data) => {
  return callApi({
    method: "GET",
    path: `/corporations/${id}`,
    data: data,
  })
}

const countForCorporationCustomPages = data => {
  return callApi({
    method: "GET",
    path: "/corporations/custom/count",
    data: data,
  })
}

const getRankCorporationDepartments = data => {
  return callApi({
    method: "GET",
    path: "/corporations/rank/departments",
    data: data,
  })
}

const getRankCorporationDepartmentsById = (id, data, dispatch)  => {
  return callApi({
    method: "GET",
    path: `/corporations/rank/departments/${id}`,
    data: data,
  })
}

const postCorporationIntentDownload = data => {
  data = { ...data, "referer": Cookies.get('kdb_referer') }
  return callApi({
    method: "POST",
    path: "/corporations/intents/download",
    data: data,
  })
}

export const CorporationsApi = {
  getCorporations,
  getCorporationsById,
  countForCorporationCustomPages,
  getRankCorporationDepartments,
  getRankCorporationDepartmentsById,
  postCorporationIntentDownload
}
