import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Location } from "@reach/router"

import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Input from "@material-ui/core/Input"
import InputAdornment from "@material-ui/core/InputAdornment"

import Hamburger from "../icons/hamburger"
import Search from "../icons/search"
import * as actions from "../../state/actions/keymans"
//import Logo from "../../images/smart-letter-logo-white.svg"
import Logo from "../../images/logo_dark.png"
import LogoWebp from "../../images/KL_logo.webp"
import { KEYMAN_PER_PAGE } from "../../constants/constants"

import { Link } from "gatsby"
import { navigate } from "@reach/router"
const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymans: (data, overwrite) =>
      dispatch(actions.getKeymans(data, overwrite)),
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    zIndex: 1,
    height: "60px",
    color: "#FFFFFF",
    background: "#0D2149",
  },

  logoContainer: {
    display: "flex",
    alignItems: "flex-end",
    flex: 1,
  },

  logoContainerOuter: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    marginRight: "20px",
  },

  searchContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },

  logo: {
    height: "36px",
    cursor: "pointer",
    marginRight: "10px",
    display: "block",
    width: "100%",
    maxWidth: "208px",

    [theme.breakpoints.down("sm")]: {
      height: "32px",
      marginRight: "8px",
      maxWidth: "185px",
    },
  },

  logoDesc: {
    margin: "auto 0",
    fontSize: "13px",
    color: "#FFFFFF",
    whiteSpace: "nowrap",
    paddingTop: "4px",

    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "10px",
    },

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  logoBottomDesc: {
    margin: "auto 0",
    fontSize: "10px",
    color: "#FFFFFF",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    paddingLeft: "40px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },

  searchRoot: {
    maxWidth: "527px",
    width: "100%",
    height: "34px",
    alignItems: "center",
    border: "1px solid #FFFFFF14",
    borderRadius: "24px",
    background: "#FFFFFF14",
    padding: "0px 5px 0px 15px",
    "& path": {
      fill: "rgba(255,255,255,0.38)",
    },
  },

  searchInput: {
    background: "none",
    border: "none",
    color: "#FFFFFF",
    fontSize: "15px",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    padding: "10px 12px",
  },

  searchFocused: {
    border: "1px solid #FFFFFF",
    "& path": {
      fill: "#FFFFFF",
    },
  },
})

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: "",
    }
  }

  handleChange = e => {
    this.setState({
      keyword: e.target.value,
    })
  }

  handleEnterKeyPressed = e => {
    if (e.keyCode === 13) {
      if (this.state.keyword) {
        if (this.props.location.pathname === "/search") {
          this.props.getKeymans(
            {
              paging: 1,
              per_page: KEYMAN_PER_PAGE,
              page: 1,
              keyword: this.state.keyword,
            },
            true
          )
        }

        navigate(`/search?keyword=${this.state.keyword}`)
      }
    }
  }

  render() {
    const { classes, location } = this.props

    return (
      <header className={classes.root} style={this.props.style}>
        <div className="container center-align">
          {location.pathname === "/" ? (
            <div className={classes.logoContainerOuter}>
              <div className={classes.logoContainer}>
                <Link to="/">
                  <picture>
                    <source
                      srcSet={LogoWebp}
                      width={185}
                      height={32}
                      type="image/webp"
                    />
                    <img
                      src={Logo}
                      width={185}
                      height={32}
                      className={classes.logo}
                      alt="ロゴ"
                    />
                  </picture>
                </Link>
              </div>
            </div>
          ) : (
            <div className={classes.searchContainer}>
              <div className={classes.logoContainerOuter}>
                <Link to="/">
                  <picture>
                    <source
                      srcSet={LogoWebp}
                      width={185}
                      height={32}
                      type="image/webp"
                    />
                    <img
                      src={Logo}
                      width={185}
                      height={32}
                      className={classes.logo}
                      alt="ロゴ"
                    />
                  </picture>
                </Link>
              </div>

              <div className="nav-inline-search">
                <Input
                  type="search"
                  placeholder="決裁者また会社を検索"
                  value={this.state.keyword}
                  onChange={this.handleChange}
                  onKeyDown={this.handleEnterKeyPressed}
                  disableUnderline={true}
                  startAdornment={
                    <InputAdornment position="start" style={{ margin: 0 }}>
                      <Search />
                    </InputAdornment>
                  }
                  classes={{
                    root: classes.searchRoot,
                    input: classes.searchInput,
                    focused: classes.searchFocused,
                  }}
                />
              </div>
            </div>
          )}
          <nav>
            <ul className="nav">
              <li className="navMenu">
                <Link to="/">ホーム</Link>
              </li>
              <li className="navMenu">
                <Link to="/intent-search">インテントデータ検索</Link>
              </li>
              <li className="navMenu">
                <Link to="/departments">部門一覧</Link>
              </li>
              <li className="navMenu">
                <Link to="/positions">役職一覧</Link>
              </li>
              <li className="navMenu">
                <Link to="/keymans">決裁者一覧</Link>
              </li>
              <li className="navMenu">
                <Link to="/corporations">会社一覧</Link>
              </li>
              <li className="navMenu">
                <Link to="/industries">業界一覧</Link>
              </li>
              <li className="navToggle">
                <IconButton onClick={this.props.toggleSidebar} aria-label="toggle_sidebar">
                  <Hamburger />
                </IconButton>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

Header.defaultProps = {}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Header {...locationProps} {...props} />}
    </Location>
  ))
)
