/* eslint react/jsx-no-target-blank: 0 */
import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

const styles = theme => ({
  root: {
    width: "100%",
    cursor: "pointer",
    display: "block",
    position: "relative",
    boxSizing: "border-box",
    background: "#0B55EF",
    "&:hover": {
      "&:before": {
        content: '""',
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        pointerEvents: "none",
        background: "rgba(0, 0, 0, 0.16)",
      },
    },
  },

  square: {
    marginBottom: "35px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "100px"
    },
  },

  rect: {
    margin: "100px auto 50px auto",

    [theme.breakpoints.down("md")]: {
      margin: "40px auto",
    },
  },

  img: {
    display: "block",
    width: "100%",
    height: "auto",
    boxShadow: "0px 0px 10px #254e781a",
  },
})

const Banner = ({ classes, short, style }) => {
  if (short) {
    return (
      <a
        className={classNames(classes.root, classes.square)}
        href="https://keymanletter.com/"
        rel="noopener"
        target="_blank"
        style={style}
        aria-label="keymanletter"
      >
        <picture>
          <source
            srcSet={require(`../../images/bnr_wide.webp`) + ", " + require(`../../images/bnr_wide@2x.webp`) + " 2x, " + require(`../../images/bnr_wide@3x.webp`) + " 3x"}
            height={156}
            width={1100}
            media="(max-width: 960px) and ( min-width: 600px)"
            type="image/webp"
          />
          <source
            srcSet={require(`../../images/bnr_wide.png`) + ", " + require(`../../images/bnr_wide@2x.png`) + " 2x, " + require(`../../images/bnr_wide@3x.png`) + " 3x"}
            height={156}
            width={1100}
            media="(max-width: 960px) and ( min-width: 600px)"
          />
          <source
            srcSet={require(`../../images/bnr_square.webp`) + ", " + require(`../../images/bnr_square@2x.webp`) + " 2x, " + require(`../../images/bnr_square@3x.webp`) + " 3x"}
            height={354}
            width={338}
            className={classes.img}
            type="image/webp"
          />
          {/* TODO: 親要素（.banner）が960px以下で非表示の場合はアクセシビリティで画像のアスペクト比が不適切と指摘されますが原因がわからず解消できていません */}
          <img
            srcSet={require(`../../images/bnr_square.png`) + ", " + require(`../../images/bnr_square@2x.png`) + " 2x, " + require(`../../images/bnr_square@3x.png`) + " 3x"}
            height={354}
            width={338}
            className={classes.img}
            alt="banner"
          />
        </picture>
      </a>
    )
  } else {
    return (
      <a
        className={classNames(classes.root, classes.rect)}
        href="https://keymanletter.com/"
        rel="noopener"
        target="_blank"
        style={style}
        aria-label="keymanletter"
      >
        <picture>
          <source
            srcSet={require(`../../images/bnr_square.webp`) + ", " + require(`../../images/bnr_square@2x.webp`) + " 2x, " + require(`../../images/bnr_square@3x.webp`) + " 3x"}
            height={354}
            width={338}
            media="(max-width: 500px)"
            type="image/webp"
          />
          <source
            srcSet={require(`../../images/bnr_square.png`) + ", " + require(`../../images/bnr_square@2x.png`) + " 2x, " + require(`../../images/bnr_square@3x.png`) + " 3x"}
            height={354}
            width={338}
            media="(max-width: 500px)"
          />
          <source
            srcSet={require(`../../images/bnr_wide.webp`) + ", " + require(`../../images/bnr_wide@2x.webp`) + " 2x, " + require(`../../images/bnr_wide@3x.webp`) + " 3x"}
            height={156}
            width={1100}
            media="(min-width: 600px)"
            type="image/webp"
          />
          <source
            srcSet={require(`../../images/bnr_wide.png`) + ", " + require(`../../images/bnr_wide@2x.png`) + " 2x, " + require(`../../images/bnr_wide@3x.png`) + " 3x"}
            height={156}
            width={1100}
            media="(min-width: 600px)"
          />
          <source
            srcSet={require(`../../images/bnr_wide.webp`) + ", " + require(`../../images/bnr_wide@2x.webp`) + " 2x, " + require(`../../images/bnr_wide@3x.webp`) + " 3x"}
            height={156}
            width={1100}
            className={classes.img}
          />
          <img
            srcSet={require(`../../images/bnr_wide.png`) + ", " + require(`../../images/bnr_wide@2x.png`) + " 2x, " + require(`../../images/bnr_wide@3x.png`) + " 3x"}
            height={156}
            width={1100}
            className={classes.img}
            alt="banner"
          />
        </picture>
      </a>
    )
  }
}

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
  short: PropTypes.bool,
}

export default withStyles(styles)(Banner)
