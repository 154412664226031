/* eslint react/jsx-no-target-blank: 0 */
import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import classNames from "classnames"

import Close from "../icons/close"

const styles = theme => ({
  root: {
    position: "fixed",
    zIndex: 1,
    right: '110px',
    [theme.breakpoints.down("md")]: {
      right: 0,
    },
  },

  closeBtnContainer: {
    position: "absolute",
    top: "14%",
    right: "2.83%",
    background: "#1A65FF",
    width: "20px",
    height: "20px",
  },

  closeBtn: {
    width: "20px",
    height: "20px",
    verticalAlign: "unset",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
  },

  popup: {
    width: "300px",
    height: "auto",
  },

  imageContent: {
    position: "relative",
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: '0',
      bottom: '0',
      width: '300px',
      height: '290px',
      zIndex: '-1',
      boxShadow: "0px 0px 10px #00000029",
    }
  },
})

const FooterPopup = ({ classes, showPopup, pageHasPopup, togglePopup, displayedFooterHeight, isDisplayedFooterPopup }) => (
  <div
    className={classNames(classes.root, "footer-popup")}
    style={{
      transform: showPopup ? "translateY(5.5px)" : "translateY(100%)",
      display: pageHasPopup ? "block" : "none",
      bottom: showPopup ? `${displayedFooterHeight}px` : 0,
      transition: showPopup && isDisplayedFooterPopup ? `unset` : "0.5s all linear",
    }}
  >
    <div className={classes.imageContent}>
      <a
        href="https://keymanletter.com/"
        target="_blank"
        rel="noopener"
      >
        <picture>
          <source
            srcSet={require("../../images/popup_PC@1x-min.webp") + ", " + require("../../images/popup_PC@2x-min.webp") + " 2x"}
            height={313}
            width={300}
            className={classes.popup}
            type="image/webp"
          />
          <img
            srcSet={require("../../images/popup_PC@1x-min.png") + ", " + require("../../images/popup_PC@2x-min.png") + " 2x"}
            height={313}
            width={300}
            className={classes.popup}
            alt="kdb-banner.png"
          />
        </picture>
      </a>
      <div className={classes.closeBtnContainer}>
        <IconButton className={classes.closeBtn} onClick={togglePopup} aria-label="close">
          <Close color="#FFFFFF" width="14" height="14" />
        </IconButton>
      </div>
    </div>
  </div>
)

FooterPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  showPopup: PropTypes.bool.isRequired,
  pageHasPopup: PropTypes.bool.isRequired,
  togglePopup: PropTypes.func.isRequired,
  displayedFooterHeight: PropTypes.number.isRequired,
  isDisplayedFooterPopup: PropTypes.bool.isRequired,
}

export default withStyles(styles)(FooterPopup)
