import axios from "axios"
import qs from "qs"

export const callApi = ({
  method,
  path,
  data = null,
  isDownload = false,
  options = null,
}) => {
  let requestOptions = options
    ? options
    : {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
        baseURL: process.env.GATSBY_BASE_URL,
        paramsSerializer: function (params) {
          return qs.stringify(params, { encode: true, arrayFormat: "indices" })
        },
      }

  if (isDownload) {
    requestOptions["responseType"] = "blob"
  }

  let request
  switch (method) {
    case "POST":
      request = axios.post(path, data, requestOptions)
      break
    case "GET":
      let options = requestOptions
      options.params = data
      request = axios.get(path, options)
      break
    case "PUT":
      request = axios.put(path, data, requestOptions)
      break
    case "PATCH":
      request = axios.patch(path, data, requestOptions)
      break
    case "DELETE":
      requestOptions.params = data
      request = axios.delete(path, requestOptions)
      break
    default:
      break
  }
  process.env.GATSBY_ACTIVE_ENV !== 'production' && console.log(request)

  return new Promise((resolve, reject) => {
    request
      .then(response => {
        process.env.GATSBY_ACTIVE_ENV !== 'production' && console.log(response)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        return reject(error)
      })
  })
}
