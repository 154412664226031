import React from "react"
import PropTypes from "prop-types"

const Hamburger = props => {
  const { color, width, height, ...other } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g id="humberger-icon" transform="translate(-428 -53)">
        <rect
          id="bg"
          width="28"
          height="28"
          transform="translate(428 53)"
          fill="none"
        />
        <g id="グループ" transform="translate(-3 8)">
          <rect
            id="bar"
            width="24"
            height="2"
            rx="1"
            transform="translate(433 58)"
            fill={color}
          />
          <rect
            id="bar-2"
            width="24"
            height="2"
            rx="1"
            transform="translate(433 64)"
            fill={color}
          />
          <rect
            id="bar-3"
            width="24"
            height="2"
            rx="1"
            transform="translate(433 52)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
}

Hamburger.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Hamburger.defaultProps = {
  color: "#FFFFFF",
  width: 28,
  height: 28,
}

export default Hamburger
