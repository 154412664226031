import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"

const styles = theme => ({
  header: {
    width: "100%",
    textAlign: "right",
    marginBottom: "5px",
  },

  dialogTitle: {
    fontWeight: "bold",
    fontSize: "20px",
  },

  dialogContent: {
    padding: "12px 32px",
  },
})

const NotificationModal = ({ classes, title, onClose, open, ...other }) => (
  <Dialog onClose={onClose} aria-labelledby="notification-modal" open={open}>
    <DialogTitle
      id="notification-modal-title"
      disableTypography
      className={classes.dialogTitle}
    >
      お知らせ
    </DialogTitle>
    <DialogContent
      classes={{
        root: classes.dialogContent,
      }}
    >
      <DialogContentText id="alert-dialog-slide-description">
        <div className={classes.header}>
          <p>2021年10月1日</p>
          <p>ヴァンテージマネジメント株式会社</p>
        </div>
        <div>
          <p>
            　当社提供のウェブマーケティングツール「Smart
            Letter」（現在は「キーマンレター」といいます。）「Keyman Data
            Base」及び「Posto」に掲載していた会社情報に関するお知らせです。
          </p>
          <p>
            　2020年2月より同年9月までの期間に掲載していた会社情報のうち、「会社プロフィール」「分析・考察」「企業情報」等について、株式会社東洋経済新報社が著作権を含む知的財産権を有するものであるにもかかわらず、同社の許諾を得ずに掲載しておりました。
          </p>
          <p>
            　これらの情報はすべて削除済みです。今後、このようなことがないよう、細心の注意を払って参ります。
          </p>
          <p>以上、お詫びし、お知らせいたします。</p>
        </div>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
)

NotificationModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(NotificationModal)
