import React from "react"
import PropTypes from "prop-types"

const Search = props => {
  const { color, width, height, ...other } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g
        id="search"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M16.617354,15.3666783 L20.7764722,19.5257965 C21.0745093,19.8238335 21.0745093,20.307047 20.7764722,20.605084 L20.605084,20.7764722 C20.307047,21.0745093 19.8238335,21.0745093 19.5257965,20.7764722 L15.3666783,16.617354 C14.0655493,17.6480113 12.4205015,18.26343 10.631715,18.26343 C6.41683519,18.26343 3,14.8465948 3,10.631715 C3,6.41683519 6.41683519,3 10.631715,3 C14.8465948,3 18.26343,6.41683519 18.26343,10.631715 C18.26343,12.4205015 17.6480113,14.0655493 16.617354,15.3666783 Z M10.631715,16.737087 C14.0036188,16.737087 16.737087,14.0036188 16.737087,10.631715 C16.737087,7.25981115 14.0036188,4.526343 10.631715,4.526343 C7.25981115,4.526343 4.526343,7.25981115 4.526343,10.631715 C4.526343,14.0036188 7.25981115,16.737087 10.631715,16.737087 Z"
          id="Combined-Shape"
          fill={color}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  )
}

Search.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Search.defaultProps = {
  color: "#000000",
  width: 24,
  height: 24,
}

export default Search
