import * as dayjs from "dayjs"
import { DEPARTMENTS, HONORIFICS, LISTING_TYPE } from "../constants/constants"

export const addCommas = number => {
  number += ""
  let parts = number.split(".")
  let integer = parts[0]
  let decimal = parts.length > 1 ? "." + parts[1] : ""
  let rgx = /(\d+)(\d{3})/
  while (rgx.test(integer)) {
    integer = integer.replace(rgx, `$1,$2`)
  }
  return integer + decimal
}

export const formatName = (contact, honorific = "") => {
  if (contact.name && (contact.company || contact.company_name))
    return `${contact.company || contact.company_name} ${
      contact.name
    } ${honorific}`
  else if (contact.name) return contact.name + " " + honorific
  else return (contact.company || contact.company_name) + " " + honorific
}

export const formatProfile = item => {
  let date = "",
    company = "",
    honorific = ""
  date =
    dayjs(
      item.relocation_at.date ? item.relocation_at.date : item.relocation_at
    ).format("YYYY年MM月") + "に"

  if (item.honorific.indexOf("当社") !== -1) {
    company = ""
  } else {
    company = item.company_name + "の"
  }

  if (item.honorific.indexOf("退任") !== -1) {
    const beforeHonorific = getBeforeHonorificExceptRetirement(item)
    // 経歴がない場合は日付 + 退任。を表示
    if (!beforeHonorific) {
      return date + "退任。"
    }
    honorific = beforeHonorific + "を退任"
  } else if (
    item.honorific.indexOf("入社") !== -1 ||
    item.honorific.indexOf("就任") !== -1 ||
    item.honorific.indexOf("着任") !== -1
  ) {
    honorific = item.honorific
  } else {
    honorific = item.honorific + "に着任"
  }

  return date + company + honorific + "。"
}

export const isJson = item => {
  try {
    JSON.parse(item)
  } catch (error) {
    return false
  }
  return true
}

export const getBeforeHonorificExceptRetirement = item => {
  const executive_biographies = isJson(item.executive_biographies) ? JSON.parse(item.executive_biographies) : item.executive_biographies
  if (executive_biographies && executive_biographies.length > 1) {
    for (let i = executive_biographies.length -1; 0 <= i; i--) {
      if (executive_biographies[i]["content"].indexOf("退任") === -1) {
        return executive_biographies[i]["content"]
      }
    }
  }
  return null
}

export const formatSeoProfile = item => {
  return `最新の経歴：${item.company_name}の${item.honorific} ${item.name} | 大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`
}

export const formatPersonalProfile = item => {
  let name = "",
    birthday = "",
    from = "",
    educationalBackgroundText = "",
    educationalBackground = []

  if (item.furigana) {
    name = `${item.name}（${item.furigana}）様は`
  }
  if (item.birthday) {
    birthday = dayjs(item.birthday).format("YYYY年MM月DD日生まれ。")
  }
  if (item.from) {
    from = `${item.from}出身。`
  }

  // 学歴
  if (item.junior_high_school) {
    educationalBackground.push(item.junior_high_school)
  }
  if (item.high_school) {
    educationalBackground.push(item.high_school)
  }
  if (item.technical_college) {
    educationalBackground.push(item.technical_college)
  }
  if (item.professional_school) {
    educationalBackground.push(item.professional_school)
  }
  if (item.university) {
    educationalBackground.push(item.university)
  }
  if (item.grad_school) {
    educationalBackground.push(item.grad_school)
  }
  if (educationalBackground.length > 0) {
    educationalBackgroundText = educationalBackground.join("、") + "を卒業。"
  }

  let profile = ""
  if (birthday || from || educationalBackgroundText) {
    // 注：ふりがなだけの場合は情報量が少ないため条件から外している
    profile = name + birthday + from + educationalBackgroundText
  }

  return profile
}

export const stripDomain = url => {
  return url
    .replace("http://", "")
    .replace("https://", "")
    .replace("www.", "")
    .split(/[/?#]/)[0]
}

export const getQueryVariable = (q = "", variable = "") => {
  let query = q[0] === "?" ? q.substring(1) : q
  let vars = query.split("&")

  let queryObj = {}

  for (let i in vars) {
    let pair = vars[i].split("=")

    if (pair.length === 2) {
      let key = decodeURIComponent(pair[0])
      let value = decodeURIComponent(pair[1])

      if (Array.isArray(variable)) {
        if (variable.find(v => v === key)) {
          queryObj[key] = value
        }
      } else {
        queryObj[key] = value
      }
    }
  }

  if (typeof variable === "string") {
    return queryObj[variable]
  } else {
    return queryObj
  }
}

/**
 * 日本語単位でフォーマットする
 * @param {number} num
 * @param {bool} truncate 最後に出た単位以下を切り捨てる
 * @return {string}
 */
export const formatJPNumberUnit = (num, truncate = true) => {
  const kName = [
    "万",
    "億",
    "兆",
    "京",
    "垓",
    "杼",
    "穰",
    "溝",
    "澗",
    "正",
    "載",
    "極",
  ]
  const str = String(num)
  let n = ""
  let count = 0
  let ptr = 0

  for (var i = str.length - 1; i >= 0; i--) {
    n = str.charAt(i) + n
    count++
    if (count % 4 === 0 && i !== 0) {
      n = kName[ptr++] + n
    }
  }

  if (truncate) {
    // 最後に出る単位以下を切り捨てる
    for (var j = n.length - 1; j >= 0; j--) {
      if (kName.indexOf(n.charAt(j)) !== -1) {
        n = n.slice(0, j + 1)
        break
      }
    }

    // 最後の単位から次の単位までが0000の場合は切り捨てる
    let complete = false
    while (!complete) {
      if (n.length < 5) {
        // xxxx万などのように単位が１つしかない場合は切り捨てられないためスキップ
        complete = true
        break
      }

      if (n.substring(n.length - 1, n.length - 5) === "0000") {
        n = n.slice(0, n.length - 5)
      } else {
        complete = true
      }
    }
  }

  return n
}

export const formatDepartment = honorific => {
  // デフォルトに「その他」とする
  let department = "その他"

  for (let i in DEPARTMENTS) {
    let keywords = DEPARTMENTS[i].name.split("・")
    for (let j in keywords) {
      if (honorific.includes(keywords[j])) {
        // 管理の場合は品質管理を除外
        if (DEPARTMENTS[i].name === "管理") {
          if (honorific.includes("品質管理")) {
            department = "品質管理"
            break
          }
        }

        // 営業の場合は営業企画・営業推進を除外
        if (DEPARTMENTS[i].name === "営業") {
          if (honorific.includes("営業企画")) {
            department = "営業企画"
            break
          }

          if (honorific.includes("営業推進")) {
            department = "営業推進"
            break
          }
        }

        // 人事の場合は法人事業、個人事業を除外
        if (DEPARTMENTS[i].name === "人事") {
          if (honorific.includes("法人事業")) {
            continue
          }

          if (honorific.includes("個人事業")) {
            continue
          }
        }

        // 経理・財務の場合、中国の現地法人で役職がある方で「有限公司総経理」のように表記されるケースを除外
        if (DEPARTMENTS[i].name === "経理・財務") {
          if (honorific.includes("公司総経理")) {
            continue
          }
        }

        // 企画の場合、「経営企画」と「営業企画」を除外
        if (DEPARTMENTS[i].name === "企画") {
          if (honorific.includes("経営企画")) {
            department = "経営企画"
            break
          }

          if (honorific.includes("営業企画")) {
            department = "営業企画"
            break
          }
        }

        // 開発の場合、「商品開発」と「研究開発」を除外
        if (DEPARTMENTS[i].name === "開発") {
          if (honorific.includes("商品開発")) {
            department = "商品開発"
            break
          }

          if (honorific.includes("研究開発")) {
            department = "研究開発・R&D"
            break
          }
        }

        department = DEPARTMENTS[i].name
        break
      }
    }
  }

  return department
}

export const formatPercentage = floatNumber => {
  return Number.parseFloat(floatNumber * 100).toFixed(2) + "%"
}

/**
 * 役職文字列から該当する役職のkeyを抽出する
 * @param {string} honorific
 * @return {string}
 */
export const resolveHonorificKey = honorific => {
  for (let i in HONORIFICS) {
    let keyword = HONORIFICS[i].name
    let searchKeywords = []
    searchKeywords.push(keyword)

    if (keyword.match(/[A-Za-z0-9]/g) !== null) {
      // アルファベットの半角は全角に変換
      keyword = keyword.replace(/[A-Za-z0-9]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) + 65248)
      })
      searchKeywords.push(keyword)
    }

    if (keyword === '代表取締役') {
      // '代表取締役', 'CEO', 'ＣＥＯ', '代表', '社長'を含んでいるかチェック
      // かつ支社長は含まない
      searchKeywords.push('CEO');
      searchKeywords.push('ＣＥＯ');
      searchKeywords.push('代表');
      searchKeywords.push('社長');
    } else if (keyword === '部長') {
      // 部長、カンパニー長を含んでいるかチェック
      searchKeywords.push('カンパニー長');
    } else if (keyword === 'マネージャー') {
      // マネージャー、マネジャー、マネージャ、GM、MGR を含んでいるかチェック
      searchKeywords.push('マネジャー');
      searchKeywords.push('マネージャ');
      searchKeywords.push('GM');
      searchKeywords.push('MGR');
    } else if (keyword === 'センター長') {
      // センター長、施設長、所長 を含んでいるかチェック
      searchKeywords.push('施設長');
      searchKeywords.push('所長');
    }

    for (let j in searchKeywords) {
      if (honorific.includes(searchKeywords[j])) {
        if (searchKeywords[j] === '社長' && honorific.includes('支社長')) {
          // 社長が検索ワードの場合支社長は含めないためスキップ
          continue
        }

        return HONORIFICS[i].value
      }
    }
  }

  return 'other'
}

/**
 * よくあるご質問の平均年収の回答
 *
 * @param {object} item
 * @return {string}
 */
export const formatIncomeAnswer = item => {
  if (!item.annual_income) {
    return null
  }

  let incomeAnswer = ''
  if (
    item.number_of_employees === 0 ||
    item.number_of_employees === "0" ||
    item.number_of_employees === "-" ||
    item.number_of_employees === ""
  ) {
    incomeAnswer = `${item.company_name}での平均年収は${item.annual_income}です。`
  } else {
    const numberOfEmployees = addCommas(item.number_of_employees) + "人"
    incomeAnswer = `${item.company_name}には従業員${numberOfEmployees}が所属しており、その平均年収は${item.annual_income}です。`
  }

  return incomeAnswer
}

/**
 * 文字列から該当する検索のkeyを抽出する
 * @param {string} key
 * @param {object} item
 * @return {string}
 */
export const resolveSearchKey = (property, item) => {
  // 従業員数の近い企業
  if (property === 'number_of_employees') {
    let value = item.number_of_employees.replace(/,/g, '')
    value = Number(value.replace('人', ''))
    if (value < 5) {
      return 'people_less_than_5'
    } else if (value >= 5 && value < 10) {
      return 'people_5_10'
    } else if (value >= 10 && value < 50) {
      return 'people_10_50'
    } else if (value >= 50 && value < 150) {
      return 'people_50_150'
    } else if (value >= 150 && value < 300) {
      return 'people_150_300'
    } else if (value >= 300 && value < 500) {
      return 'people_300_500'
    } else if (value >= 500 && value < 1000) {
      return 'people_500_1000'
    } else if (value >= 1000 && value < 3000) {
      return 'people_1000_3000'
    } else if (value >= 3000 && value < 5000) {
      return 'people_3000_5000'
    } else if (value >= 5000 && value < 10000) {
      return 'people_5000_10000'
    } else if (value >= 10000) {
      return 'people_10000_over'
    }
  }

  // 設立日の近い企業
  if (property === 'establishment_year') {
    if (item && !!item.corporation_detail_detail) {
      const detail = JSON.parse(item.corporation_detail_detail)
      if (detail.hasOwnProperty("設立日")) {
        return detail['設立日'].indexOf('年') !== -1 ? detail['設立日'].substr(0, detail['設立日'].indexOf('年')): ''
      }
    }
  }

  // 売上高の近い企業
  if (property === 'amount_of_sales') {
    let value = item.amount_of_sales
    if (value < 100000000) {
      return 'yen_less_than_1'
    } else if (value >= 100000000 && value < 300000000) {
      return 'yen_1_3'
    } else if (value >= 300000000 && value < 1000000000) {
      return 'yen_3_10'
    } else if (value >= 1000000000 && value < 5000000000) {
      return 'yen_10_50'
    } else if (value >= 5000000000 && value < 10000000000) {
      return 'yen_50_100'
    } else if (value >= 10000000000 && value < 20000000000) {
      return 'yen_100_200'
    } else if (value >= 20000000000 && value < 30000000000) {
      return 'yen_200_300'
    } else if (value >= 30000000000 && value < 40000000000) {
      return 'yen_300_400'
    } else if (value >= 40000000000 && value < 50000000000) {
      return 'yen_400_500'
    } else if (value >= 50000000000 && value < 60000000000) {
      return 'yen_500_600'
    } else if (value >= 60000000000 && value < 70000000000) {
      return 'yen_600_700'
    } else if (value >= 70000000000 && value < 80000000000) {
      return 'yen_700_800'
    } else if (value >= 80000000000 && value < 90000000000) {
      return 'yen_800_900'
    } else if (value >= 90000000000 && value < 100000000000) {
      return 'yen_900_1000'
    } else if (value >= 100000000000 && value < 300000000000) {
      return 'yen_1000_3000'
    } else if (value >= 300000000000) {
      return 'yen_3000_over'
    }
  }

  // 資本金の近い企業
  if (property === 'capital_stock') {
    let value = item.capital_stock
    if (value < 50000000) {
        return 'yen_less_than_5000'
      } else if (value >= 50000000 && value < 300000000) {
        return 'yen_5000_30000'
      } else if (value >= 300000000 && value < 1000000000) {
        return 'yen_30000_1000000'
      } else if (value >= 1000000000 && value < 5000000000) {
        return 'yen_1000000_5000000'
      } else if (value >= 5000000000 && value < 15000000000) {
        return 'yen_5000000_150000000'
      } else if (value >= 15000000000) {
        return 'yen_150000000_over'
    }
  }

  // 平均年齢の近い企業
  if (property === 'average_age') {
    let value = Number(item.average_age.replace('歳', ''))
    if (value < 30) {
      return 'age_less_than_30'
    } else if (value >= 30 && value < 40) {
      return 'age_30_40'
    } else if (value >= 40 && value < 50) {
      return 'age_40_50'
    } else if (value >= 50) {
      return 'age_50_over'
    }
  }

  // 平均年収の近い企業
  if (property === 'annual_income') {
    let value = Number(item.annual_income.replace('万円', ''))
    if (value < 300) {
      return 'income_less_than_300'
    } else if (value >= 300 && value < 400) {
      return 'income_300_400'
    } else if (value >= 400 && value < 500) {
      return 'income_400_500'
    } else if (value >= 500 && value < 600) {
      return 'income_500_600'
    } else if (value >= 600 && value < 700) {
      return 'income_600_700'
    } else if (value >= 700 && value < 800) {
      return 'income_700_800'
    } else if (value >= 800 && value < 900) {
      return 'income_800_900'
    } else if (value >= 900 && value < 1000) {
      return 'income_900_1000'
    } else if (value >= 1000 && value < 1500) {
      return 'income_1000_1500'
    } else if (value >= 1500 && value < 2000) {
      return 'income_1500_2000'
    } else if (value >= 2000) {
      return 'income_2000_over'
    }
  }

  // 他の上場企業
  if (property === 'listing_type') {
    let value = item.listing_type
    const listingType = LISTING_TYPE.find(elem => elem.name === value)
    if (listingType !== undefined) {
      return listingType.value
    }
  }

  // 決算月が同じ企業
  if (property === 'clothing_month') {
    return item.clothing_month
  }

  // 本社の近い企業
  // 村、町は検索範囲が狭くなってしまうと思い、検索対象は必ず含まれる「市」「郡」「区」としています。
  if (property === 'main_office') {
    let value = item.city_name
    if (value.indexOf('市') !== -1) {
      value = value.substr(0, value.indexOf('市', 1) + 1) //市川市など先頭に市がつく市があるため2文字目から検索する
    } else if (value.indexOf('郡') !== -1) {
      value = value.substr(0, value.indexOf('郡') + 1)
    } else if (value.indexOf('区') !== -1) {
      value = value.substr(0, value.indexOf('区') + 1)
    }
    return value
  }

  return null;
}