import { callApi } from "./api-utils"

const getKeymans = data => {
  return callApi({
    method: "GET",
    path: "/keymans",
    data: data,
  })
}

const getKeymansRelevant = data => {
  return callApi({
    method: "GET",
    path: "/keymans/relevant",
    data: data,
  })
}

const getKeymansById = (id, data) => {
  return callApi({
    method: "GET",
    path: `/keymans/${id}`,
    data: data,
  })
}

const getKeymansCount = data => {
  return callApi({
    method: "GET",
    path: "/keymans/count",
    data: data,
  })
}

const getRankDepartments = data => {
  return callApi({
    method: "GET",
    path: "/keymans/rank/departments",
    data: data,
  })
}

const getRankJsicL = data => {
  return callApi({
    method: "GET",
    path: "/keymans/rank/jsic_l",
    data: data,
  })
}

const getRankDepartmentsById = (id, data, dispatch) => {
  return callApi({
    method: "GET",
    path: `/keymans/rank/departments/${id}`,
    data: data,
  })
}

const getRankJsicLById = (id, data, dispatch) => {
  return callApi({
    method: "GET",
    path: `/keymans/rank/jsic_l/${id}`,
    data: data,
  })
}

const getRankJsicSById = (id, data, dispatch) => {
  return callApi({
    method: "GET",
    path: `/keymans/rank/jsic_s/${id}`,
    data: data,
  })
}

const getExecutiveCount = data => {
  return callApi({
    method: "GET",
    path: "/keymans/executive_count",
    data: data,
  })
}

export const KeymansApi = {
  getKeymans,
  getKeymansRelevant,
  getKeymansById,
  getKeymansCount,
  getRankDepartments,
  getRankJsicL,
  getRankDepartmentsById,
  getRankJsicLById,
  getRankJsicSById,
  getExecutiveCount,
}
