import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"

import Header from "./header"
import Footer from "./footer"
import Sidebar from "./sidebar"
import FooterPopup from "./footer-popup"
import FooterBannerPopup from "./footer-banner-popup"
// import CenterPopup from "./center-popup"
import NotificationModal from "./notification-modal"

import "../../css/index.css"
import { DEPARTMENTS, INDUSTRIES, HONORIFICS, PREFECTURES } from "../../constants/constants"
import classNames from "classnames"

const styles = theme => ({
  title: {
    fontSize: "32px",
    textAlign: "center",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
    },
  },

  section: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px #254E781A",
    position: "relative",
  },

  keymanSection: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px #254E781A",
    position: "relative",
    marginTop: "40px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
  },

  corpSection: {
    margin: "25px auto 100px",
    [theme.breakpoints.down("xs")]: {
      margin: "25px auto 140px"
    },
  },

  listTitle: {
    padding: "30px 40px 20px 40px",
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down("xs")]: {
      padding: "25px 30px",
    },
  },

  listContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    background: "#FFFFFF",
    padding: "0 40px 16px 40px",
    margin: "0",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 30px 16px 30px",
    },
  },

  listItem: {
    listStyle: "none",
    width: "33.33%",
    marginBottom: "14px",
    "& a": {
      fontSize: "12px",
      color: "#71737B",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },

    [theme.breakpoints.between("xs", "sm")]: {
      width: "50%",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  link: {
    fontSize: "15px",
    fontWeight: "bold",
    textDecoration: "none",
    height: "unset",
    padding: "2px 4px",
    color: "inherit",

    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },

  arrowDown: {
    width: '6px',
    height: '6px',
    border: '2px solid',
    borderColor:  'transparent transparent #1866FF #1866FF',
    transform: 'rotate(-45deg)',
    margin: 'auto 0',
  },

  arrowTop: {
    width: '6px',
    height: '6px',
    border: '2px solid',
    borderColor: '#1866FF #1866FF transparent transparent',
    transform: 'rotate(-45deg)',
    margin: 'auto 0',
  },
})

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSidebar: false,
      showFooterPopup: false,
      showFooterBannerPopup: false,
      // showCenterPopup: false, //非表示のためコメントアウト
      showNotificationModal: false,
      displayedFooterHeight: 0,
      isDisplayedFooterPopup: false,
      isMounted: true,
      isMobile: false,
      departmentsSearchMenu: false,
      industriesSearchMenu: false,
      positionsSearchMenu: false,
      locationSearchMenu: false,
    }
  }

  isDisplayWidthRange = () => {
    return window.innerWidth < 960;
  }

  toggleSearchMenu = (menu) => {
    this.setState({
      [menu]: !this.state[menu],
    }, () => this.handleScroll())
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && window.innerWidth < 600) {
      this.setState({
        isMobile: true,
      })
    }

    const isDisplayWidthRange = this.isDisplayWidthRange();
    if (!!document) document.addEventListener('scroll', () => this.handleScroll(), true)
    // ウインドウサイズが規定幅以下の場合、時間経過でフッターバナーを表示する
    if (isDisplayWidthRange) {
      this.timeout = setTimeout(() => {
        this.setState({
          showFooterBannerPopup: true,
        })
      }, 5000);

    // PCの場合、時間経過でポップアップを表示する
    } else {
      this.timeout = setTimeout(() => {
        let showFooterPopup = JSON.parse(
          sessionStorage.getItem("showFooterPopup")
        )

        if (showFooterPopup === null) {
          sessionStorage.setItem("showFooterPopup", true)

          this.setState({
            showFooterPopup: true,
          })
        }
      }, 5000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
    this.setState({
      isMounted: false
    });
    if (!!document) document.removeEventListener("scroll", this.handleScroll())
  }

  openSidebar = () => {
    this.setState({ showSidebar: true })
  }

  closeSidebar = () => {
    this.setState({ showSidebar: false })
  }

  // toggleCenterPopup = () => {
  //   sessionStorage.setItem("showCenterPopup", false)
  //   this.setState({
  //     showCenterPopup: false,
  //   })
  // }

  toggleFooterBannerPopup = () => {
    this.setState({ showFooterBannerPopup: !this.state.showFooterBannerPopup })
  }

  toggleFooterPopup = () => {
    this.setState({ showFooterPopup: !this.state.showFooterPopup })
  }

  toggleNotificationModal = () => {
    this.setState({ showNotificationModal: !this.state.showNotificationModal })
  }

  // フッターの表示領域を取得
  getFooterDisplayArea = () => {
    const footerElement = document.getElementById('footer');
    return !!footerElement ? window.innerHeight - footerElement.getBoundingClientRect().top : 0
  };

  handleScroll = () => {
    const footerAreaHeight = this.getFooterDisplayArea()
    const isDisplayWidthRange = this.isDisplayWidthRange();
    // フッターが表示されている場合はポップアップの表示位置をフッターの上に表示する
    if (this.state.isMounted && footerAreaHeight > 0) {
      this.setState({
        displayedFooterHeight: isDisplayWidthRange ? footerAreaHeight + 1.1 : footerAreaHeight + 2.5,
        isDisplayedFooterPopup: this.state.showFooterBannerPopup || this.state.showFooterPopup ? true : false,
      })
    } else if (this.state.isMounted && this.state.displayedFooterHeight !== 0) {
      this.setState({
        displayedFooterHeight: 0,
      })
    }
  }

  renderMenuTitle = (menu, titleText) => {
    const { classes } = this.props
    // スマホの場合はアコーディオンメニューに切り替える
    if (this.state.isMobile) {
      return (
        <div
          role='button'
          tabIndex='0'
          className={classNames(classes.listTitle, "title")}
          style={{ marginBottom: "0px", borderBottom:  this.state[menu] ? '1px solid rgb(216, 219, 223)' : 'none', }}
          onClick={() => this.toggleSearchMenu(menu)}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              this.toggleSearchMenu(menu)
            }
          }}
        >
          <div>{titleText}</div>
          <div className={this.state && this.state[menu] ? classes.arrowTop : classes.arrowDown}></div>
        </div>
      )
    } else {
      return (
        <h2 className={classNames(classes.listTitle, "title")} style={{ marginBottom: "0px" }}>
          {titleText}
        </h2>
      )
    }
  }

  render() {
    const { classes, hasFooterBannerPopup, hasFooterPopup, hasExecutiveSearchFromOther, hasExecutiveSearchFormCorporation } = this.props
    const { isMobile } = this.state
    return (
      <div className="root">
        <Header toggleSidebar={this.openSidebar} />
        <div className="main">{this.props.children}</div>

        {hasExecutiveSearchFromOther && (
          <React.Fragment>
            <div className={classes.title} >その他の決裁者から探す</div>
            <div className={classNames(classes.section, "container")} style={{ marginTop: "25px" }}>
              {this.renderMenuTitle('departmentsSearchMenu', '部門から探す')}
              <ul
                className={classes.listContainer}
                style={{display: !isMobile ? 'flex' : isMobile && this.state.departmentsSearchMenu ? 'flex' : 'none'}}
              >
                {DEPARTMENTS.map((item, i) => (
                  <li className={classes.listItem} key={i}>
                    <Link key={i} to={`/departments/${item.value}`}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={classNames(classes.keymanSection, "container")}>
              {this.renderMenuTitle('industriesSearchMenu', '業界から探す')}
              <ul
                className={classes.listContainer}
                style={{display: !isMobile ? 'flex' : isMobile && this.state.industriesSearchMenu ? 'flex' : 'none'}}
              >
                {INDUSTRIES.map((item, i) => (
                  <li className={classes.listItem} key={i}>
                    <Link key={i} to={`/industries/${item.value}`}>
                      {item.value}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className={classNames(classes.keymanSection, "container")}>
              {this.renderMenuTitle('positionsSearchMenu', '役職から探す')}
              <ul
                className={classes.listContainer}
                style={{display: !isMobile ? 'flex' : isMobile && this.state.positionsSearchMenu ? 'flex' : 'none'}}
              >
                {HONORIFICS.map((item, i) => (
                  <li className={classes.listItem} key={i}>
                    <Link key={i} to={`/positions/${item.value}`}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        )}

        {hasExecutiveSearchFormCorporation && (
          <React.Fragment>
            <div className={classes.title} style={{ marginTop: "60px" }}>企業から決裁者を探す</div>
            <div className={classNames(classes.section, "container", classes.corpSection)}>
              {this.renderMenuTitle('locationSearchMenu', '地域から企業を探す')}
              <ul
                className={classes.listContainer}
                style={{display: !isMobile ? 'flex' : isMobile && this.state.locationSearchMenu ? 'flex' : 'none'}}
              >
                {PREFECTURES.map((item, i) => (
                  <li className={classes.listItem} key={i}>
                    <Link key={i} to={`/corporations-location-search/${item.value}`}>
                      {item.value}地方
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        )}

        <Footer />
        <Sidebar
          open={this.state.showSidebar}
          toggleSidebar={this.closeSidebar}
        />
        {/* <CenterPopup
          showPopup={this.state.showCenterPopup}
          togglePopup={this.toggleCenterPopup}
        /> */}
        <FooterBannerPopup
          showPopup={this.state.showFooterBannerPopup}
          pageHasPopup={hasFooterBannerPopup}
          isDisplayedFooterPopup={this.state.isDisplayedFooterPopup}
          displayedFooterHeight={this.state.displayedFooterHeight}
          togglePopup={this.toggleFooterBannerPopup}
        />
        <FooterPopup
          showPopup={this.state.showFooterPopup}
          pageHasPopup={hasFooterPopup}
          isDisplayedFooterPopup={this.state.isDisplayedFooterPopup}
          displayedFooterHeight={this.state.displayedFooterHeight}
          togglePopup={this.toggleFooterPopup}
        />
        <NotificationModal
          open={this.state.showNotificationModal}
          onClose={this.toggleNotificationModal}
        />
      </div>
    )
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  hasFooterPopup: true,
  hasFooterBannerPopup: true,
  hasExecutiveSearchFromOther: true,
  hasExecutiveSearchFormCorporation: true,
}

export default withStyles(styles)(Layout)
