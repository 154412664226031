import {
  GET_CORPORATIONS_REQUEST,
  GET_CORPORATIONS_SUCCESS,
  GET_CORPORATIONS_FAILURE,
  GET_INTENT_CORPORATIONS_REQUEST,
  GET_INTENT_CORPORATIONS_SUCCESS,
  GET_INTENT_CORPORATIONS_FAILURE,
  GET_CORPORATIONS_BY_ID_REQUEST,
  GET_CORPORATIONS_BY_ID_SUCCESS,
  GET_CORPORATIONS_BY_ID_FAILURE,
  GET_KEYMANS_REQUEST,
  GET_KEYMANS_SUCCESS,
  GET_KEYMANS_FAILURE,
  GET_KEYMANS_RELEVANT_REQUEST,
  GET_KEYMANS_RELEVANT_SUCCESS,
  GET_KEYMANS_RELEVANT_FAILURE,
  GET_KEYMANS_HOME_REQUEST,
  GET_KEYMANS_HOME_SUCCESS,
  GET_KEYMANS_HOME_FAILURE,
  GET_KEYMANS_BY_ID_REQUEST,
  GET_KEYMANS_BY_ID_SUCCESS,
  GET_KEYMANS_BY_ID_FAILURE,
  GET_KEYMANS_YESTERDAY_SUCCESS,
  GET_KEYMANS_YESTERDAY_FAILURE,
  GET_KEYMANS_TOTAL_SUCCESS,
  GET_KEYMANS_TOTAL_FAILURE,
  GET_KEYMANS_COUNT_REQUEST,
  GET_KEYMANS_COUNT_SUCCESS,
  GET_KEYMANS_COUNT_FAILURE,
  GET_KEYMANS_DEPARTMENTS_SUCCESS,
  GET_KEYMANS_POSITIONS_SUCCESS,
  GET_DEPARTMENTS_BY_ID_REQUEST,
  GET_DEPARTMENTS_BY_ID_SUCCESS,
  GET_DEPARTMENTS_BY_ID_FAILURE,
  GET_POSITIONS_BY_ID_SUCCESS,
  GET_POSITIONS_BY_ID_FAILURE,
  GET_KEYMANS_INDUSTRIES_SUCCESS,
  GET_INDUSTRIES_BY_ID_SUCCESS,
  GET_INDUSTRIES_BY_ID_FAILURE,
  COUNT_FOR_CUSTOM_PAGES_REQUEST,
  COUNT_FOR_CUSTOM_PAGES_SUCCESS,
  COUNT_FOR_CUSTOM_PAGES_FAILURE,
  GET_RANK_DEPARTMENTS_REQUEST,
  GET_RANK_DEPARTMENTS_SUCCESS,
  GET_RANK_DEPARTMENTS_FAILURE,
  GET_RANK_JSIC_L_DEPARTMENTS_REQUEST,
  GET_RANK_JSIC_L_DEPARTMENTS_SUCCESS,
  GET_RANK_JSIC_L_DEPARTMENTS_FAILURE,
  GET_RANK_CORPORATION_DEPARTMENTS_REQUEST,
  GET_RANK_CORPORATION_DEPARTMENTS_SUCCESS,
  GET_RANK_CORPORATION_DEPARTMENTS_FAILURE,
  GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_REQUEST,
  GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_SUCCESS,
  GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_FAILURE,
  GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_REQUEST,
  GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_SUCCESS,
  GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_FAILURE,
  GET_RANK_JSIC_L_REQUEST,
  GET_RANK_JSIC_L_SUCCESS,
  GET_RANK_JSIC_L_FAILURE,
  GET_RANK_DEPARTMENTS_BY_ID_REQUEST,
  GET_RANK_DEPARTMENTS_BY_ID_SUCCESS,
  GET_RANK_DEPARTMENTS_BY_ID_FAILURE,
  GET_RANK_JSIC_L_BY_ID_REQUEST,
  GET_RANK_JSIC_L_BY_ID_SUCCESS,
  GET_RANK_JSIC_L_BY_ID_FAILURE,
  GET_RANK_JSIC_S_BY_ID_REQUEST,
  GET_RANK_JSIC_S_BY_ID_SUCCESS,
  GET_RANK_JSIC_S_BY_ID_FAILURE,
  GET_EXECUTIVE_COUNT_REQUEST,
  GET_EXECUTIVE_COUNT_SUCCESS,
  GET_EXECUTIVE_COUNT_FAILURE,
} from "../../constants/action-types"
import { CorporationsApi } from "../api/corporations-api"
import { KeymansApi } from "../api/keymans-api"
import { IndustriesApi } from "../api/industries-api"
import {
  KEYMAN_PER_PAGE,
  DEPARTMENTS,
  HONORIFICS,
  INDUSTRIES,
} from "../../constants/constants"

import * as dayjs from "dayjs"

/*** GET /keymans ***/
const getKeymansRequest = (overwrite, data) => ({
  type: GET_KEYMANS_REQUEST,
  payload: {
    overwrite: overwrite,
    page: data["page"],
    keyword: data["keyword"],
  },
})

const getKeymansSuccess = (keymans, pageInfo, data) => ({
  type: GET_KEYMANS_SUCCESS,
  payload: {
    keymans: keymans,
    pageInfo: pageInfo,
    keyword: data["keyword"],
  },
})

const getKeymansFailure = error => ({
  type: GET_KEYMANS_FAILURE,
  payload: {
    error: error,
  },
})

export function getKeymans(data, overwrite = false) {
  return async dispatch => {
    dispatch(getKeymansRequest(overwrite, data))

    KeymansApi.getKeymans(data)
      .then(response => {
        dispatch(
          getKeymansSuccess(response.data.data, response.data.meta, data)
        )
      })
      .catch(error => {
        dispatch(getKeymansFailure(error.response.data))
      })
  }
}

/*** GET /keymans/relevant ***/
const getKeymansRelevantRequest = () => ({
  type: GET_KEYMANS_RELEVANT_REQUEST,
})

const getKeymansRelevantSuccess = (data) => ({
  type: GET_KEYMANS_RELEVANT_SUCCESS,
  payload: {
    data: data,
  },
})

const getKeymansRelevantFailure = error => ({
  type: GET_KEYMANS_RELEVANT_FAILURE,
  payload: {
    error: error,
  },
})

export function getKeymansRelevant(data) {
  return async dispatch => {
    dispatch(getKeymansRelevantRequest())

    KeymansApi.getKeymansRelevant(data)
      .then(response => {
        dispatch(getKeymansRelevantSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getKeymansRelevantFailure(error.response.data))
      })
  }
}


/*** GET /keymans ***/
const getKeymansHomeRequest = overwrite => ({
  type: GET_KEYMANS_HOME_REQUEST,
  payload: {
    overwrite: overwrite,
  },
})

const getKeymansHomeSuccess = (keymans, pageInfo) => ({
  type: GET_KEYMANS_HOME_SUCCESS,
  payload: {
    keymans: keymans,
    pageInfo: pageInfo,
  },
})

const getKeymansHomeFailure = error => ({
  type: GET_KEYMANS_HOME_FAILURE,
  payload: {
    error: error,
  },
})

export function getKeymansHome(data, overwrite = false) {
  return async dispatch => {
    dispatch(getKeymansHomeRequest(overwrite))

    KeymansApi.getKeymans(data)
      .then(response => {
        dispatch(getKeymansHomeSuccess(response.data.data, response.data.meta))
      })
      .catch(error => {
        dispatch(getKeymansHomeFailure(error.response.data))
      })
  }
}

/*** GET /keymans_by_id ***/
const getKeymansByIdRequest = id => ({
  type: GET_KEYMANS_BY_ID_REQUEST,
  payload: {
    id: id,
  },
})

const getKeymansByIdSuccess = (id, item) => ({
  type: GET_KEYMANS_BY_ID_SUCCESS,
  payload: {
    id: id,
    item: item,
  },
})

const getKeymansByIdFailure = (id, error) => ({
  type: GET_KEYMANS_BY_ID_FAILURE,
  payload: {
    id: id,
    error: error,
  },
})

export function getKeymansById(id) {
  return async dispatch => {
    dispatch(getKeymansByIdRequest(id))

    KeymansApi.getKeymansById(id, null)
      .then(response => {
        dispatch(getKeymansByIdSuccess(id, response.data.data))
        if (
          response.data.data &&
          response.data.data.hasOwnProperty("corporation_id")
        ) {
          dispatch(
            getKeymans(
              {
                corporation_id: [response.data.data.corporation_id],
                per_page: KEYMAN_PER_PAGE,
                paginng: 1,
                page: 1,
                exclude_keyman_id: [response.data.data.id],
              },
              true
            )
          )
        }
      })
      .catch(error => {
        dispatch(getKeymansByIdFailure(id, error.response))
      })
  }
}

/*** GET /corporations ***/
const getCorporationsRequest = (overwrite, page) => ({
  type: GET_CORPORATIONS_REQUEST,
  payload: {
    overwrite: overwrite,
    page: typeof page === "string" ? parseInt(page, 10) : page,
  },
})

const getCorporationsSuccess = (corporations, pageInfo) => ({
  type: GET_CORPORATIONS_SUCCESS,
  payload: {
    corporations: corporations,
    pageInfo: pageInfo,
  },
})

const getCorporationsFailure = error => ({
  type: GET_CORPORATIONS_FAILURE,
  payload: {
    error: error,
  },
})

export function getCorporations(data = {}, overwrite = false) {
  return async dispatch => {
    dispatch(getCorporationsRequest(overwrite, data["page"]))

    CorporationsApi.getCorporations(data)
      .then(response => {
        dispatch(getCorporationsSuccess(response.data.data, response.data.meta))
      })
      .catch(error => {
        dispatch(getCorporationsFailure(error.response.data))
      })
  }
}

/*** GET /corporations インテント検索用 ***/
const getIntentCorporationsRequest = (overwrite, page) => ({
  type: GET_INTENT_CORPORATIONS_REQUEST,
  payload: {
    overwrite: overwrite,
    page: typeof page === "string" ? parseInt(page, 10) : page,
  },
})

const getIntentCorporationsSuccess = (intentCorporations, pageInfo) => ({
  type: GET_INTENT_CORPORATIONS_SUCCESS,
  payload: {
    intentCorporations: intentCorporations,
    pageInfo: pageInfo,
  },
})

const getIntentCorporationsFailure = error => ({
  type: GET_INTENT_CORPORATIONS_FAILURE,
  payload: {
    error: error,
  },
})

export function getIntentCorporations(data = {}, overwrite = false) {
  return async dispatch => {
    dispatch(getIntentCorporationsRequest(overwrite, data["page"]))

    // APIはgetCorporationsを使う
    CorporationsApi.getCorporations(data)
      .then(response => {
        dispatch(getIntentCorporationsSuccess(response.data.data, response.data.meta))
      })
      .catch(error => {
        dispatch(getIntentCorporationsFailure(error.response.data))
      })
  }
}

/*** GET /corporations_by_id ***/
const getCorporationsByIdRequest = id => ({
  type: GET_CORPORATIONS_BY_ID_REQUEST,
  payload: {
    id: id,
  },
})

const getCorporationsByIdSuccess = (id, item) => ({
  type: GET_CORPORATIONS_BY_ID_SUCCESS,
  payload: {
    id: id,
    item: item,
  },
})

const getCorporationsByIdFailure = (id, error) => ({
  type: GET_CORPORATIONS_BY_ID_FAILURE,
  payload: {
    id: id,
    error: error,
  },
})

export function getCorporationsById(id) {
  let isCustomPage = false;
  if (typeof window !== 'undefined') {
    if (window.location.href.match("corporations/(.*)/departments")) {
      isCustomPage = true;
    } else if (window.location.href.match("corporations/(.*)/positions")) {
      isCustomPage = true;
    }
  }
  return async dispatch => {
    dispatch(getCorporationsByIdRequest(id))

    CorporationsApi.getCorporationsById(id, null)
      .then(response => {
        dispatch(getCorporationsByIdSuccess(id, response.data.data))
        if (response.data.data && response.data.data.hasOwnProperty("id") && !isCustomPage) {
          dispatch(
            getKeymans(
              {
                corporation_id: [response.data.data.id],
                per_page: KEYMAN_PER_PAGE,
                paginng: 1,
                page: 1,
              },
              true
            )
          )
        }
      })
      .catch(error => {
        dispatch(getCorporationsByIdFailure(id, error.response))
      })
  }
}

/*** GET /keymans 昨日更新された決裁者の人数 ***/
const getKeymansYesterdaySuccess = (updateKeymans, pageInfo) => ({
  type: GET_KEYMANS_YESTERDAY_SUCCESS,
  payload: {
    updateKeymans: updateKeymans,
    count: pageInfo && pageInfo.total,
  },
})

const getKeymansYesterdayFailure = error => ({
  type: GET_KEYMANS_YESTERDAY_FAILURE,
  payload: {
    error: error,
  },
})

export function getKeymansYesterday() {
  return async dispatch => {
    KeymansApi.getKeymans(
      {
        per_page: 1,
        paging: 1,
        page: 1,
        created_at: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      },
    )
      .then(response => {
        dispatch(getKeymansYesterdaySuccess(response.data.data, response.data.meta))
      })
      .catch(error => {
        dispatch(getKeymansYesterdayFailure(error.response.data))
      })
  }
}

/*** GET /keymans 会社と決裁者のトータルを取得する ***/
const getKeymansTotalSuccess = pageInfo => ({
  type: GET_KEYMANS_TOTAL_SUCCESS,
  payload: {
    keymansTotal: pageInfo && pageInfo.total,
    corporationsTotal: pageInfo && pageInfo.corporation_count,
  },
})

const getKeymansTotalFailure = error => ({
  type: GET_KEYMANS_TOTAL_FAILURE,
  payload: {
    error: error,
  },
})

// 件数の値がブレてしまうためKLと同じくハローワークも含めた件数を取得する（検索結果には出さない）
export function getKeymansTotal(data) {
  return async dispatch => {
    KeymansApi.getKeymans(
      {
        per_page: 0,
        paging: 1,
        page: 1,
        include_corporation_count: true,
        include_hellowork: 1,
        relocation_at: ['before2018'],
        exclude_retirement: [true],
      },
      dispatch
    )
      .then(response => {
        dispatch(getKeymansTotalSuccess(response.data.meta))
      })
      .catch(error => {
        dispatch(getKeymansTotalFailure(error.response))
      })
  }
}

const countForIndustyCustomPagesRequest = () => ({
  type: COUNT_FOR_CUSTOM_PAGES_REQUEST,
})

const countForIndustyCustomPagesSuccess = (data, page, pageType) => ({
  type: COUNT_FOR_CUSTOM_PAGES_SUCCESS,
  payload: {
    data: data,
    page: page,
    pageType: pageType
  },
})

const countForIndustyCustomPagesFailure = error => ({
  type: COUNT_FOR_CUSTOM_PAGES_FAILURE,
  payload: {
    error: error,
  },
})

/*** GET /departments/custom/count 部門のかけあわせページの決裁者のトータルを取得する ***/
export function countForIndustyCustomPages(data) {
  return async (dispatch) => {
    dispatch(countForIndustyCustomPagesRequest())
    IndustriesApi.countForIndustyCustomPages(
        data
    )
      .then(response => {
        dispatch(countForIndustyCustomPagesSuccess(response.data.data, response.data.page, response.data.pageType))
      })
      .catch(error => {
        dispatch(countForIndustyCustomPagesFailure(error.response))
      })
  }
}

const countForCorporationCustomPagesRequest = () => ({
  type: COUNT_FOR_CUSTOM_PAGES_REQUEST,
})

const countForCorporationCustomPagesSuccess = (data, page, pageType) => ({
  type: COUNT_FOR_CUSTOM_PAGES_SUCCESS,
  payload: {
    data: data,
    page: page,
    pageType: pageType
  },
})

const countForCorporationCustomPagesFailure = error => ({
  type: COUNT_FOR_CUSTOM_PAGES_FAILURE,
  payload: {
    error: error,
  },
})

/*** GET /corporations/custom/count 企業のかけあわせページの決裁者のトータルを取得する ***/
export function countForCorporationCustomPages(data) {
  return async (dispatch) => {
    dispatch(countForCorporationCustomPagesRequest())
    CorporationsApi.countForCorporationCustomPages(
        data
    )
      .then(response => {
        dispatch(countForCorporationCustomPagesSuccess(response.data.data, response.data.page, response.data.pageType))
      })
      .catch(error => {
        dispatch(countForCorporationCustomPagesFailure(error.response))
      })
  }
}

/*** GET /keymans/count ***/
const getKeymansCountRequest = () => ({
  type: GET_KEYMANS_COUNT_REQUEST,
})

const getKeymansCountSuccess = data => ({
  type: GET_KEYMANS_COUNT_SUCCESS,
  payload: {
    data: data,
  },
})

const getKeymansCountFailure = error => ({
  type: GET_KEYMANS_COUNT_FAILURE,
  payload: {
    error: error,
  },
})

export function getKeymansCount() {
  return async dispatch => {
    dispatch(getKeymansCountRequest())

    KeymansApi.getKeymansCount(
      {
        department: DEPARTMENTS.map(item => item.value),
        honorific: HONORIFICS.map(item => item.value),
        jsic_l: INDUSTRIES.map(item => item.value),
      },
      dispatch
    )
      .then(response => {
        dispatch(getKeymansCountSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getKeymansCountFailure(error.response))
      })
  }
}

/*** GET /keymans/executive_count ***/
const getExecutiveCountRequest = () => ({
  type: GET_EXECUTIVE_COUNT_REQUEST,
})

const getExecutiveCountSuccess = data => ({
  type: GET_EXECUTIVE_COUNT_SUCCESS,
  payload: {
    data: data,
  },
})

const getExecutiveCountFailure = error => ({
  type: GET_EXECUTIVE_COUNT_FAILURE,
  payload: {
    error: error,
  },
})

export function getExecutiveCount() {
  return async dispatch => {
    dispatch(getExecutiveCountRequest())
    KeymansApi.getExecutiveCount(dispatch)
      .then(response => {
        dispatch(getExecutiveCountSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getExecutiveCountFailure(error.response))
      })
  }
}

const getKeymansPositionsSuccess = (page, perPage) => ({
  type: GET_KEYMANS_POSITIONS_SUCCESS,
  payload: {
    page: typeof page === "string" ? parseInt(page, 10) : page,
    per_page: perPage,
  },
})

export function getKeymansPositions(data) {
  return async dispatch => {
    dispatch(getKeymansPositionsSuccess(data.page, data.per_page))
  }
}

const getKeymansDepartmentsSuccess = (page, perPage) => ({
  type: GET_KEYMANS_DEPARTMENTS_SUCCESS,
  payload: {
    page: typeof page === "string" ? parseInt(page, 10) : page,
    per_page: perPage,
  },
})

export function getKeymansDepartments(data) {
  return async dispatch => {
    dispatch(getKeymansDepartmentsSuccess(data.page, data.per_page))
  }
}

/*** GET /keymans/rank/jsic_s/departments ***/
const getDepartmentsByIdRequest = () => ({
  type: GET_DEPARTMENTS_BY_ID_REQUEST,
})

const getDepartmentsByIdSuccess = department => ({
  type: GET_DEPARTMENTS_BY_ID_SUCCESS,
  payload: {
    department: department,
  },
})

const getDepartmentsByIdFailure = error => ({
  type: GET_DEPARTMENTS_BY_ID_FAILURE,
  payload: {
    error: error,
  },
})

export function getDepartmentsById(key) {
  return async (dispatch) => {
    dispatch(getDepartmentsByIdRequest())
    KeymansApi.getKeymansCount(
      {
        department: [key],
      },
    )
    .then(response => {
      dispatch(getDepartmentsByIdSuccess( response.data.data['department']))
    })
    .catch(error => {
      dispatch(getDepartmentsByIdFailure(error.response))
    })
  }
}

const getPositionsByIdSuccess = position => ({
  type: GET_POSITIONS_BY_ID_SUCCESS,
  payload: {
    position: position,
  },
})

const getPositionsByIdFailure = error => ({
  type: GET_POSITIONS_BY_ID_FAILURE,
  payload: {
    error: error,
  },
})

export function getPositionsById(key) {
  return async (dispatch, getState) => {
    let allPositions = getState().positions.allPositions
    let position = allPositions.get(key)

    if (position && position.hasOwnProperty("count")) {
      dispatch(getPositionsByIdSuccess(position))
    } else {
      KeymansApi.getKeymansCount(
        {
          honorific: [key],
        },
        dispatch
      )
        .then(response => {
          let count = response.data.data["honorific"][key]
          position = HONORIFICS.find(item => item.value === key)
          dispatch(getPositionsByIdSuccess({ ...position, count: count }))
        })
        .catch(error => {
          dispatch(getPositionsByIdFailure(error.response))
        })
    }
  }
}

const getKeymansIndustriesSuccess = (page, perPage) => ({
  type: GET_KEYMANS_INDUSTRIES_SUCCESS,
  payload: {
    page: typeof page === "string" ? parseInt(page, 10) : page,
    per_page: perPage,
  },
})

export function getKeymansIndustries(data) {
  return async dispatch => {
    dispatch(getKeymansIndustriesSuccess(data.page, data.per_page))
  }
}

const getIndustriesByIdSuccess = industry => ({
  type: GET_INDUSTRIES_BY_ID_SUCCESS,
  payload: {
    industry: industry,
  },
})

const getIndustriesByIdFailure = error => ({
  type: GET_INDUSTRIES_BY_ID_FAILURE,
  payload: {
    error: error,
  },
})

export function getIndustriesById(key) {
  return async (dispatch, getState) => {
    let allIndustries = getState().industries.allIndustries
    let industry = allIndustries.get(key)

    if (industry && industry.hasOwnProperty("count")) {
      dispatch(getIndustriesByIdSuccess(industry))
    } else {
      KeymansApi.getKeymansCount(
        {
          jsic_l: [key],
        },
        dispatch
      )
        .then(response => {
          let count = response.data.data["jsic_l"][key]
          industry = INDUSTRIES.find(item => item.value === key)
          dispatch(getIndustriesByIdSuccess({ ...industry, count: count }))
        })
        .catch(error => {
          dispatch(getIndustriesByIdFailure(error.response))
        })
    }
  }
}

/*** GET /keymans/rank/jsic_l/departments ***/
const getRankJsicLDepartmentsRequest = () => ({
  type: GET_RANK_JSIC_L_DEPARTMENTS_REQUEST,
})

const getRankJsicLDepartmentsSuccess = data => ({
  type: GET_RANK_JSIC_L_DEPARTMENTS_SUCCESS,
  payload: {
    data: data,
  },
})

const getRankJsicLDepartmentsFailure = error => ({
  type: GET_RANK_JSIC_L_DEPARTMENTS_FAILURE,
  payload: {
    error: error,
  },
})

export function getRankJsicLDepartments(data) {
  return async dispatch => {
    dispatch(getRankJsicLDepartmentsRequest())

    IndustriesApi.getRankJsicLDepartments(data, dispatch)
      .then(response => {
        dispatch(getRankJsicLDepartmentsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getRankJsicLDepartmentsFailure(error.response))
      })
  }
}

/*** GET /keymans/rank/corporation/departments ***/
const getRankCorporationDepartmentsRequest = () => ({
  type: GET_RANK_CORPORATION_DEPARTMENTS_REQUEST,
})

const getRankCorporationDepartmentsSuccess = data => ({
  type: GET_RANK_CORPORATION_DEPARTMENTS_SUCCESS,
  payload: {
    data: data,
  },
})

const getRankCorporationDepartmentsFailure = error => ({
  type: GET_RANK_CORPORATION_DEPARTMENTS_FAILURE,
  payload: {
    error: error,
  },
})

export function getRankCorporationDepartments(data) {
  return async dispatch => {
    dispatch(getRankCorporationDepartmentsRequest())

    CorporationsApi.getRankCorporationDepartments(data, dispatch)
      .then(response => {
        dispatch(getRankCorporationDepartmentsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getRankCorporationDepartmentsFailure(error.response))
      })
  }
}

/*** GET /keymans/rank/departments ***/
const getRankDepartmentsRequest = () => ({
  type: GET_RANK_DEPARTMENTS_REQUEST,
})

const getRankDepartmentsSuccess = data => ({
  type: GET_RANK_DEPARTMENTS_SUCCESS,
  payload: {
    data: data,
  },
})

const getRankDepartmentsFailure = error => ({
  type: GET_RANK_DEPARTMENTS_FAILURE,
  payload: {
    error: error,
  },
})

export function getRankDepartments(data) {
  return async dispatch => {
    dispatch(getRankDepartmentsRequest())

    KeymansApi.getRankDepartments(data, dispatch)
      .then(response => {
        dispatch(getRankDepartmentsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getRankDepartmentsFailure(error.response))
      })
  }
}

/*** GET /keymans/rank/jsic_l ***/
const getRankJsicLRequest = () => ({
  type: GET_RANK_JSIC_L_REQUEST,
})

const getRankJsicLSuccess = data => ({
  type: GET_RANK_JSIC_L_SUCCESS,
  payload: {
    data: data,
  },
})

const getRankJsicLFailure = error => ({
  type: GET_RANK_JSIC_L_FAILURE,
  payload: {
    error: error,
  },
})

export function getRankJsicL(data) {
  return async dispatch => {
    dispatch(getRankJsicLRequest())

    KeymansApi.getRankJsicL(data, dispatch)
      .then(response => {
        dispatch(getRankJsicLSuccess(response.data))
      })
      .catch(error => {
        dispatch(getRankJsicLFailure(error.response))
      })
  }
}

/*** GET /keymans/rank/departments/{id} ***/
const getRankDepartmentsByIdRequest = () => ({
  type: GET_RANK_DEPARTMENTS_BY_ID_REQUEST,
})

const getRankDepartmentsByIdSuccess = data => ({
  type: GET_RANK_DEPARTMENTS_BY_ID_SUCCESS,
  payload: {
    data: data,
  },
})

const getRankDepartmentsByIdFailure = error => ({
  type: GET_RANK_DEPARTMENTS_BY_ID_FAILURE,
  payload: {
    error: error,
  },
})

export function getRankDepartmentsById(id, data) {
  return async dispatch => {
    dispatch(getRankDepartmentsByIdRequest())

    KeymansApi.getRankDepartmentsById(id, data, dispatch)
      .then(response => {
        dispatch(getRankDepartmentsByIdSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getRankDepartmentsByIdFailure(error.response))
      })
  }
}

/*** GET /keymans/rank/jsic_l/departments/{id} ***/
const getRankJsicLDepartmentsByIdRequest = () => ({
  type: GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_REQUEST,
})

const getRankJsicLDepartmentsByIdSuccess = data => ({
  type: GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_SUCCESS,
  payload: {
    data: data,
  },
})

const getRankJsicLDepartmentsByIdFailure = error => ({
  type: GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_FAILURE,
  payload: {
    error: error,
  },
})

export function getRankJsicLDepartmentsById(id, data) {
  return async dispatch => {
    dispatch(getRankJsicLDepartmentsByIdRequest())

    IndustriesApi.getRankJsicLDepartmentsById(id, data, dispatch)
      .then(response => {
        dispatch(getRankJsicLDepartmentsByIdSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getRankJsicLDepartmentsByIdFailure(error.response))
      })
  }
}

/*** GET /keymans/rank/corporation/departments/{id} ***/
const getRankCorporationDepartmentsByIdRequest = () => ({
  type: GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_REQUEST,
})

const getRankCorporationDepartmentsByIdSuccess = data => ({
  type: GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_SUCCESS,
  payload: {
    data: data,
  },
})

const getRankCorporationDepartmentsByIdFailure = error => ({
  type: GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_FAILURE,
  payload: {
    error: error,
  },
})

export function getRankCorporationDepartmentsById(id, data) {
  return async dispatch => {
    dispatch(getRankCorporationDepartmentsByIdRequest())

    CorporationsApi.getRankCorporationDepartmentsById(id, data, dispatch)
      .then(response => {
        dispatch(getRankCorporationDepartmentsByIdSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getRankCorporationDepartmentsByIdFailure(error.response))
      })
  }
}

/*** GET /keymans/rank/jsic_l/{id} ***/
const getRankJsicLByIdRequest = () => ({
  type: GET_RANK_JSIC_L_BY_ID_REQUEST,
})

const getRankJsicLByIdSuccess = data => ({
  type: GET_RANK_JSIC_L_BY_ID_SUCCESS,
  payload: {
    data: data,
  },
})

const getRankJsicLByIdFailure = error => ({
  type: GET_RANK_JSIC_L_BY_ID_FAILURE,
  payload: {
    error: error,
  },
})

export function getRankJsicLById(id, data) {
  return async dispatch => {
    dispatch(getRankJsicLByIdRequest())

    KeymansApi.getRankJsicLById(id, data, dispatch)
      .then(response => {
        dispatch(getRankJsicLByIdSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getRankJsicLByIdFailure(error.response))
      })
  }
}

/*** GET /keymans/rank/jsic_s/{id} ***/
const getRankJsicSByIdRequest = () => ({
  type: GET_RANK_JSIC_S_BY_ID_REQUEST,
})

const getRankJsicSByIdSuccess = data => ({
  type: GET_RANK_JSIC_S_BY_ID_SUCCESS,
  payload: {
    data: data,
  },
})

const getRankJsicSByIdFailure = error => ({
  type: GET_RANK_JSIC_S_BY_ID_FAILURE,
  payload: {
    error: error,
  },
})

export function getRankJsicSById(id, data) {
  return async dispatch => {
    dispatch(getRankJsicSByIdRequest())

    KeymansApi.getRankJsicSById(id, data, dispatch)
      .then(response => {
        dispatch(getRankJsicSByIdSuccess(response.data.data))
      })
      .catch(error => {
        dispatch(getRankJsicSByIdFailure(error.response))
      })
  }
}