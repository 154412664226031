import { callApi } from "./api-utils"

const countForIndustyCustomPages = data => {
  return callApi({
    method: "GET",
    path: "/industries/custom/count",
    data: data,
  })
}

const getRankJsicLDepartments = data => {
  return callApi({
    method: "GET",
    path: "/industries/rank/jsic_L_custom_pages",
    data: data,
  })
}

const getRankJsicLDepartmentsById = (id, data, dispatch)  => {
  return callApi({
    method: "GET",
    path: `/industries/rank/jsic_L_custom_pages/${id}`,
    data: data,
  })
}

export const IndustriesApi = {
  countForIndustyCustomPages,
  getRankJsicLDepartments,
  getRankJsicLDepartmentsById,
}
