import React from "react"
import PropTypes from "prop-types"

const Close = props => {
  const { color, width, height, ...other } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path id="Shape" d="M0,0H34V34H0Z" fill="none" fillRule="evenodd" />
      <path
        id="Combined-Shape"
        d="M14.917,12.9l7.477-7.477a1.429,1.429,0,0,1,2.021,2.021l-7.477,7.477,7.477,7.477a1.429,1.429,0,0,1-2.021,2.021l-7.477-7.477L7.439,24.415a1.429,1.429,0,0,1-2.021-2.021L12.9,14.917,5.419,7.439A1.429,1.429,0,0,1,7.439,5.419Z"
        transform="translate(2.083 2.083)"
        fill={color}
      />
    </svg>
  )
}

Close.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Close.defaultProps = {
  color: "#000000",
  width: 34,
  height: 34,
}

export default Close
