/* eslint react/jsx-no-target-blank: 0 */
import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

import dayjs from "dayjs"

const styles = theme => ({
  root: {
    width: "100%",
    zIndex: 1,
    height: "106px",
    color: "#B7B7B7",
    background: "#0D2149",
    fontSize: 13,
    [theme.breakpoints.between(1024, 1125)]: {
      fontSize: 12,
    },
  },

  nav: {
    display: "flex",
    alignItems: "center",
    padding: "0",
    margin: "0",

    "& li:first-child": {
      marginLeft: "0px",
    },

    "& li": {
      listStyle: "none",
      marginLeft: 16,
      cursor: "pointer",

      "& a": {
        color: "#B7B7B7",
        textDecoration: "none",
        "&:hover": {
          color: "#FFFFFF",
        },
      },
    },

    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
})

class Footer extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.root} id="footer" >
        <div className="container center-align">
          <div>©︎ {dayjs().year()} Keyman Database All rights reserved.</div>
          <nav>
            <ul className={classes.nav}>
              <li>
                <a
                  href="https://www.vantage.jp/index.php#company"
                  rel="noopener"
                  target="_blank"
                >
                  運営会社
                </a>
              </li>
              <li>
                <a
                  href="https://keymanletter.com/register-account/"
                  rel="noopener"
                  target="_blank"
                >
                  お問い合わせ
                </a>
              </li>
              <li>
                <Link to="/sitemap">サイトマップ</Link>
              </li>
              <li>
                <a
                  href="https://keymanletter.com/terms/"
                  rel="noopener"
                  target="_blank"
                >
                  利用規約
                </a>
              </li>
              <li>
                <a
                  href="https://keymanletter.com/userdata_external_transmission/"
                  rel="noopener"
                  target="_blank"
                >
                  外部送信ポリシー
                </a>
              </li>
              <li>
                <a
                  href="https://www.vantage.jp/privacypolicy/index.php"
                  rel="noopener"
                  target="_blank"
                >
                  個人情報保護方針
                </a>
              </li>
              <li>
                <a
                  href="https://keymanletter.com/optout/"
                  rel="noopener"
                  target="_blank"
                >
                  オプトアウトについて
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    )
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Footer)
