/* eslint react/jsx-no-target-blank: 0 */
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { navigate, Location } from "@reach/router"

import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Input from "@material-ui/core/Input"
import InputAdornment from "@material-ui/core/InputAdornment"

import Close from "../icons/close"
import Logo from "../../images/logo_dark.png"
import LogoWebp from "../../images/KL_logo.webp"
import { DEPARTMENTS, KEYMAN_PER_PAGE } from "../../constants/constants"
import Search from "../icons/search"
import * as actions from "../../state/actions/keymans"

const styles = theme => ({
  root: {
    background: "#0D2149",
    width: "0",
    height: "100vh",
    overflowY: "scroll",
    zIndex: 10,
    position: "fixed",
    right: 0,
    top: 0,
    visibility: "visible",
    transition: "0.3s",
    padding: "0",
    justifyContent: "flex-start",
    boxSizing: "border-box",

    "& ul": {
      color: "#FFFFFF",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      minWidth: "320px",
      padding: "20px 40px 80px 40px",
      boxSizing: "border-box",

      "& li": {
        "& a": {
          fontSize: "16px",
          color: "#FFFFFF",
          textDecoration: "none",
          "&:hover": {
            color: "#B7B7B7",
          },
        },
        marginTop: "30px",
        listStyle: "none",
      },
    },
  },

  closeBtn: {
    position: "absolute",
    right: "40px",
    top: "10px",
    margin: "0 !important",

    [theme.breakpoints.between("xs", "sm")]: {
      right: "40px",
    },

    [theme.breakpoints.down("xs")]: {
      right: "20px",
    },
  },

  sidebarLogoContainer: {
    height: "25px",
    cursor: "pointer",
    margin: "0px 0px 30px 0px !important",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },

  logo: {
    height: "32px",
    width: "185px",
    cursor: "pointer",
    marginBottom: "2px",
  },

  logoDesc: {
    fontSize: "13px",
    color: "#FFFFFF",
    whiteSpace: "nowrap",
  },

  divider: {
    borderBottom: "1px solid #D8DBDF",
    width: "100%",
    display: "block",
    marginTop: "40px",
  },

  submenu: {
    padding: "0px 30px !important",
  },

  submenuItem: {
    marginTop: "18px !important",
  },

  searchRoot: {
    maxWidth: "527px",
    width: "100%",
    height: "34px",
    alignItems: "center",
    border: "1px solid #FFFFFF14",
    borderRadius: "24px",
    background: "#FFFFFF14",
    padding: "0px 5px 0px 15px",
    "& path": {
      fill: "rgba(255,255,255,0.38)",
    },
  },

  searchInput: {
    background: "none",
    border: "none",
    color: "#FFFFFF",
    fontSize: "15px",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    padding: "10px 12px",
  },

  searchFocused: {
    border: "1px solid #FFFFFF",
    "& path": {
      fill: "#FFFFFF",
    },
  },
})

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymans: (data, overwrite) =>
      dispatch(actions.getKeymans(data, overwrite)),
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: "",
    }
  }

  handleChange = e => {
    this.setState({
      keyword: e.target.value,
    })
  }

  handleEnterKeyPressed = e => {
    if (e.keyCode === 13) {
      if (this.state.keyword) {
        if (this.props.location.pathname === "/search") {
          this.props.getKeymans(
            {
              paging: 1,
              per_page: KEYMAN_PER_PAGE,
              page: 1,
              keyword: this.state.keyword,
            },
            true
          )
        }
        navigate(`/search?keyword=${this.state.keyword}`)
        this.props.toggleSidebar();
      }
    }
  }

  render() {
    const { classes, open, toggleSidebar } = this.props
    return (
      <div
        className={classes.root}
        style={{
          width: open ? "100%" : "0",
          visibility: open ? "visible" : "hidden",
        }}
      >
        <nav>
          <ul>
            <li className={classes.closeBtn}>
              <IconButton onClick={toggleSidebar}>
                <Close width={25} height={25} color="#FFFFFF" />
              </IconButton>
            </li>
            <li className={classes.sidebarLogoContainer}>
              <Link to="/">
                <picture>
                  <source
                    srcSet={LogoWebp}
                    height={32}
                    width={185}
                    className={classes.logo}
                    type="image/webp"
                  />
                  <img
                    src={Logo}
                    height={32}
                    width={185}
                    className={classes.logo}
                    alt="smart-letter-logo.svg"
                  />
                </picture>
              </Link>
            </li>

            <li className="nav-sidebar-search">
              <Input
                type="search"
                placeholder="決裁者また会社を検索"
                value={this.state.keyword}
                onChange={this.handleChange}
                onKeyDown={this.handleEnterKeyPressed}
                disableUnderline={true}
                startAdornment={
                  <InputAdornment position="start" style={{ margin: 0 }}>
                    <Search />
                  </InputAdornment>
                }
                classes={{
                  root: classes.searchRoot,
                  input: classes.searchInput,
                  focused: classes.searchFocused,
                }}
              />
            </li>

            <li>
              <Link to="/">ホーム</Link>
            </li>
            <li>
                <Link to="/intent-search">インテントデータ検索</Link>
            </li>
            <li>
              <Link to="/departments">部門一覧</Link>
              <ul className={classes.submenu}>
                {DEPARTMENTS.map((item, i) => (
                  <li className={classes.submenuItem} key={i}>
                    <Link
                      key={i}
                      className={classes.listItem}
                      to={`/departments/${item.value}`}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link to="/positions">役職一覧</Link>
            </li>
            <li>
              <Link to="/keymans">決裁者一覧</Link>
            </li>
            <li>
              <Link to="/corporations">会社一覧</Link>
            </li>
            <li>
              <Link to="/industries">業界一覧</Link>
            </li>

            <span className={classes.divider} />

            <li>
              <a
                href="https://www.vantage.jp/index.php#company"
                rel="noopener"
                target="_blank"
                onClick={toggleSidebar}
              >
                運営会社
              </a>
            </li>
            <li>
              <a
                href="https://keymanletter.com/register-account/"
                rel="noopener"
                target="_blank"
                onClick={toggleSidebar}
              >
                お問い合わせ
              </a>
            </li>
            <li>
              <Link to="/sitemap">サイトマップ</Link>
            </li>
            <li>
              <a
                href="https://keymanletter.com/terms/"
                rel="noopener"
                target="_blank"
                onClick={toggleSidebar}
              >
                利用規約
              </a>
            </li>
            <li>
              <a
                href="https://keymanletter.com/userdata_external_transmission/"
                rel="noopener"
                target="_blank"
              >
                外部送信ポリシー
              </a>
            </li>
            <li>
              <a
                href="https://www.vantage.jp/privacypolicy/index.php"
                rel="noopener"
                target="_blank"
                onClick={toggleSidebar}
              >
                個人情報保護方針
              </a>
            </li>
            <li>
              <a
                href="https://keymanletter.com/optout/"
                rel="noopener"
                target="_blank"
                onClick={toggleSidebar}
              >
                オプトアウトについて
              </a>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Sidebar {...locationProps} {...props} />}
    </Location>
  ))
)
